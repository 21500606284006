import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, ProductDetail, productsDetailAdapter } from './products-detail.entity';
import { sessionStorageReducer } from '../session-storage.reducer';

const STATE_KEY = 'ProductsDetailState';

export const createProductsDetailState = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    fetchProductsDetailSuccess(state, { payload }: PayloadAction<ProductDetail[]>) {
      productsDetailAdapter.setAll(state.productsDetail, payload);
    }
  },
  extraReducers: builder => {
    sessionStorageReducer(builder, STATE_KEY, ['fetchProductsDetailSuccess']);
  }
});

export const productsDetailActions = createProductsDetailState.actions;
