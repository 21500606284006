import { References } from '@pages/payment/components/payment-info/store/payment-info.entity';
import { Product } from '../products/products.entity';

export interface UtilitiesPaymentRq {
  originProductId: string;
  originProductType: string;
  paymentAmount: number;
  transactionTraceabilityCode: string;
  destinationAgreementId: string;
  destinationInvoiceNie: string;
  destinationInvoiceNumber: string;
}

export interface ObligationsPaymentRq {
  originProductId: string;
  originProductType: string;
  paymentAmount: number;
  transactionTraceabilityCode: string;
  destinationProductId: string;
  destinationProductType: string;
  destinationBankId: string;
  avalPaymentTypeCode: string;
  paymentDescription: string;
  references: References;
}

export interface PaymentsGenericResponse {
  message: string;
  approvalId: string;
  requestId: string;
}

export interface PaymentState {
  paymentResponse: PaymentsGenericResponse;
  productSelected: Product;
  error: Error;
}

export const initialState: PaymentState = {
  paymentResponse: null,
  productSelected: null,
  error: null
};
