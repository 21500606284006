export const TEALIUM_CATEGORIES = {
  '': 'login',
  login: 'login',
  payment: 'summary',
  voucher: 'voucher',
  otp: 'otp',
  token: 'token'
};

export const getCurrentURL = (): string => {
  return window.location.href;
};

export const getCurrentModule = (): string => {
  const path = window.location.pathname;
  const module: string = path.split('/')[1];
  if (module.includes('?')) return module.split('?')[0];

  return TEALIUM_CATEGORIES[module];
};
