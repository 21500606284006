import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, PaymentInfoState } from './payment-info.entity';
import { sessionStorageReducer } from '@core/store/session-storage.reducer';

export const createPaymentInfoState = createSlice({
  name: 'PaymentInfoState',
  initialState,
  reducers: {
    paymentInfoSuccess(_, { payload }: PayloadAction<PaymentInfoState>) {
      return { ...payload };
    },
    paymentInfoError() {
      return { ...initialState };
    },
    updateTransactionStatus(state, { payload }: PayloadAction<string>) {
      return { ...state, transactionStatus: payload };
    }
  },
  extraReducers: builder => {
    sessionStorageReducer(builder, 'PaymentInfoState', ['paymentInfoSuccess, updateTransactionStatus']);
  }
});

export const paymentInfoActions = createPaymentInfoState.actions;
