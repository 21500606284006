import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { isLoginSuccess } from '@core/store/user/user.entity';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps): JSX.Element => {
  const isLoggedIn = useSelector(isLoginSuccess);
  return <Route {...rest} render={props => (isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)} />;
};

export default PrivateRoute;
