import React, { useEffect, useRef, useState } from 'react';

import styles from './IdleNotification.module.scss';
import { BdbMlModal } from '@npm-bbta/bbog-dig-dt-webcomponents-lib/dist/components';
import { useIdle } from './hooks/idle.hook';
import { getMmSsTime } from '@utils/dateUtils';
import { tealium } from '@utils/tealium.utils';
import { getModalTypeDesc } from '@utils/notification.utils';
import { NotificationTypes } from '@notification';

const MODAL_TITLE = '¿Aún estás ahí?';

const IdleNotification = (): JSX.Element => {
  const { isIdle, isTimeout, remaining } = useIdle();
  const modalRef = useRef(null);

  const [message, setMessage] = useState('');

  useEffect(() => {
    modalRef.current.hiddenButtons = true;
    modalRef.current.isClose = false;
  }, []);

  useEffect(() => {
    const formatTime = getMmSsTime(remaining);
    setMessage(`El tiempo para completar tu pago finalizará en ${formatTime} minutos.`);
  }, [remaining]);

  useEffect(() => {
    if (isTimeout && modalRef.current.handleCloseClick) {
      modalRef.current.handleCloseClick();
    }
  }, [isTimeout]);

  useEffect(() => {
    if (isIdle && modalRef.current.openAlert) {
      modalRef.current.openAlert();
      tealium.link({
        Event_Label: `${MODAL_TITLE}: ${message}`,
        ga_event: getModalTypeDesc(NotificationTypes.INFORMATION)
      });
    } else if (!isIdle && !isTimeout && modalRef.current.handleCloseClick) {
      modalRef.current.handleCloseClick();
    }
  }, [isIdle]);

  return (
    <div className={styles['idle-notification-container']}>
      <BdbMlModal
        data-testid="idle-modal"
        ref={modalRef}
        titleModal={MODAL_TITLE}
        subTitle={message}
        icon={NotificationTypes.INFORMATION}
      ></BdbMlModal>
    </div>
  );
};

export default IdleNotification;
