import { RootState } from '@core/store';
import { PaymentInfoState } from './payment-info.entity';

export interface PaymentInfoDataSherpa {
  transactionTraceabilityCode: string;
  transactionStatus: string;
  paymentAmount: number;
  paymentType: string;
  destinationAgreementName: string;
  destinationInvoiceNie: string;
  destinationInvoiceNumber: string;
  destinationBankId: string;
  destinationProductId: string;
  destinationProductType: string;
  avalPaymentTypeCode: string;
}

export const paymentInfoSelector = (state: RootState): PaymentInfoState => state.paymentInfoState;

export const getPaymentInfoToSherpaSelector = (state: RootState): PaymentInfoDataSherpa => {
  const paymentInfo: PaymentInfoState = paymentInfoSelector(state);
  return {
    transactionTraceabilityCode: paymentInfo.transactionTraceabilityCode,
    transactionStatus: paymentInfo.transactionStatus,
    paymentAmount: paymentInfo.paymentAmount,
    paymentType: paymentInfo.paymentType,
    destinationAgreementName: paymentInfo.destinationAgreementName,
    destinationInvoiceNie: paymentInfo.destinationInvoiceNie,
    destinationInvoiceNumber: paymentInfo.destinationInvoiceNumber,
    destinationBankId: paymentInfo.destinationBankId,
    destinationProductId: paymentInfo.destinationProductId,
    destinationProductType: paymentInfo.destinationProductType,
    avalPaymentTypeCode: paymentInfo.avalPaymentTypeCode
  };
};
