import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, UserState } from './user.entity';
import { sessionStorageReducer } from '../session-storage.reducer';

export const createUserState = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    loginSuccess(state, { payload }: PayloadAction<UserState>) {
      return {
        ...payload,
        traceability: state.traceability
      };
    },
    setTraceabilityCode(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        traceability: payload
      };
    },
    reset() {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    sessionStorageReducer(builder, 'AuthState', ['loginSuccess', 'reset']);
  }
});

export const userActions = createUserState.actions;
