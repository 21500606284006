import { RootState } from '@core/store/index';

export interface PaymentInfoState {
  transactionTraceabilityCode: string;
  transactionStatus: string;
  paymentAmount: number;
  paymentType: string;
  destinationBankId: string;
  destinationProductId: string;
  destinationProductType: string;
  avalPaymentTypeCode: string;
  destinationAgreementId: string;
  destinationAgreementName: string;
  destinationInvoiceNie: string;
  destinationInvoiceNumber: string;
  references: References;
}

export const initialState: PaymentInfoState = {
  transactionTraceabilityCode: null,
  transactionStatus: null,
  paymentAmount: null,
  paymentType: null,
  destinationBankId: null,
  destinationProductId: null,
  destinationProductType: null,
  avalPaymentTypeCode: null,
  destinationAgreementId: null,
  destinationAgreementName: null,
  destinationInvoiceNie: null,
  destinationInvoiceNumber: null,
  references: null
};

export interface References {
  BankName: string;
  'X-Channel': string;
  PortalURL: string;
  TokenizedData: string;
  BankId: string;
  Template: string;
  Coin: string;
}

export const paymentInfoState = (state: RootState): PaymentInfoState => state.paymentInfoState;
