import { Dispatch } from '@reduxjs/toolkit';

import { PaymentInfoRq } from '../models/payment-info.models';
import { loadingHandler } from '@components/loader/store/loader.store';
import { callPaymentInfoApi, callUpdatePaymentInfoApi } from './payment-info.api';
import { paymentInfoActions } from './payment-info.reducer';
import { NotificationControl, NotificationData } from '../../../../../../notification/Notification';
import showNotificationModal from '@notification-modal';
import { AdlResponse } from '@avaldigitallabs/bbog-pb-lib-frontend-commons';
import { UpdatePaymentInfoRq } from '../models/update-payment-info.model';
import { userActions } from '@core/store/user/user.reducer';
import { BdbGenericRequest } from '@core/models/api-models';
import { RootState } from '@core/store';
import { ERROR_MESSAGE, EXIT_BUTTON_TEXT, TIMEOUT_MESSAGE } from '@core/constants/view';

const defaultCustomer: BdbGenericRequest = {
  customer: {
    identificationType: 'CC',
    identificationNumber: '0000',
    channel: 'PGW',
    remoteAddress: '127.0.0.1'
  }
};

export const callUpdatePayInfoEffect = (traceId: string): (() => Promise<void>) => {
  const action = async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const {
      userSettingsState: { customer }
    } = getState();
    try {
      await callUpdatePaymentInfoApi(buildUpdatePayInfoRq(traceId, buildGenericRq(customer)));
      dispatch(userActions.setTraceabilityCode(''));
    } catch (error) {
      console.error('ERROR: unable to abort the payment process:', error);
    }
  };
  return loadingHandler.bind(null, action);
};

const buildGenericRq = (customerState: { [key: string]: string } | null): BdbGenericRequest => {
  if (customerState) {
    const { identificationType, identificationNumber, remoteAddress } = customerState;
    return {
      customer: {
        identificationType,
        identificationNumber,
        channel: 'PGW',
        remoteAddress
      }
    };
  }
  return defaultCustomer;
};

const buildUpdatePayInfoRq = (traceId: string, customer: BdbGenericRequest): UpdatePaymentInfoRq => {
  const CANCELLED_AUTH_CODE = '02';
  return {
    ...customer,
    transactionTraceabilityCode: traceId,
    transactionStatus: 'ABORTED',
    authorizationCode: CANCELLED_AUTH_CODE
  };
};

export const callPayInfoEffect = (traceId: string): (() => Promise<void>) => {
  const action = async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const {
      paymentInfoState: { references }
    } = getState();
    try {
      const paymentInfoRs = await callPaymentInfoApi(buildPaymentInfoRq(traceId));
      if (paymentInfoRs) {
        dispatch(userActions.setTraceabilityCode(traceId));
        let notificationData: NotificationData = null;
        switch (paymentInfoRs.transactionStatus) {
          case 'CONFIRMED_OK':
            notificationData = {
              type: 'error',
              name: 'Pago duplicado',
              message: 'En las últimas 24 horas hiciste un pago con las mismas características.'
            };
            break;
          case 'BANK_TIMEOUT':
            notificationData = TIMEOUT_MESSAGE;
            break;
          case 'CANCELLED':
          case 'FAILED':
          case 'CONFIRMED_NA':
          case 'EXPIRED':
            notificationData = ERROR_MESSAGE;
            break;
          default:
            dispatch(paymentInfoActions.paymentInfoSuccess(paymentInfoRs));
            break;
        }
        buildNotificationMessage(notificationData, references?.PortalURL);
      }
    } catch (error) {
      buildNotificationErrorMessage(error, references?.PortalURL);
    }
  };
  return loadingHandler.bind(null, action);
};

export const buildPaymentInfoRq = (traceabilityCode: string): PaymentInfoRq => {
  return {
    ...defaultCustomer,
    trnRqUID: traceabilityCode
  };
};

const buildNotificationMessage = (notificationData: NotificationData, portalURL: string): void => {
  if (notificationData) {
    const actions: NotificationControl[] = [
      {
        text: EXIT_BUTTON_TEXT,
        action: () => {
          sessionStorage.clear();
          window.location.assign(portalURL || process.env.APC_HOME_URL);
        }
      }
    ];
    showNotificationModal(notificationData, actions);
  }
};

const buildNotificationErrorMessage = ({ data }: AdlResponse, portalURL: string): void => {
  let notificationData: NotificationData = ERROR_MESSAGE;
  if (data && data.customerErrorMessage) {
    notificationData = {
      type: 'error',
      name: data.customerErrorMessage.title,
      message: data.customerErrorMessage.message
    };
  }
  const actions: NotificationControl[] = [
    {
      text: EXIT_BUTTON_TEXT,
      action: () => {
        sessionStorage.clear();
        window.location.assign(portalURL || process.env.APC_HOME_URL);
      }
    }
  ];
  showNotificationModal(notificationData, actions);
};
