import { AxiosResponse } from 'axios';
import { PaymentInfoState } from './payment-info.entity';
import { PaymentInfoRq } from '../models/payment-info.models';
import { UpdatePaymentInfoRq } from '../models/update-payment-info.model';
import { BdbGenericResponse } from '@core/models/api-models';
import { axiosADLInstance } from '@core/constants/constants';

export const callPaymentInfoApi = (request: PaymentInfoRq): Promise<PaymentInfoState> => {
  return axiosADLInstance
    .post<PaymentInfoRq, AxiosResponse<PaymentInfoState>>('payment-gateway/get-payment-info', request)
    .then(response => response.data);
};

export const callUpdatePaymentInfoApi = (request: UpdatePaymentInfoRq): Promise<BdbGenericResponse> => {
  return axiosADLInstance
    .post<UpdatePaymentInfoRq, AxiosResponse<BdbGenericResponse>>('payment-gateway/update-transaction-status', request)
    .then(response => response.data);
};
