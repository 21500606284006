import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { createNotificationState } from '@components/bdb-ml-modal/store/notification.store';
import { createSherpaToastState } from '@components/bdb-at-toast/store/toast.store';
import { createLoaderState } from '@components/loader/store/loader.store';
import { createSendEmailState } from '@components/modal-email/store/send-email.reducer';
import { createTokenState } from '../../two-factor-authentication/token/store/token.reducer';
import { createUserSettingsState } from './user-features/user-features.reducer';
import { createProductsState } from './products/products.reducer';
import { createUserState } from './user/user.reducer';
import { createOTPState } from '../../two-factor-authentication/otp/store/otp.reducer';
import { createPaymentInfoState } from '../../app/pages/payment/components/payment-info/store/payment-info.reducer';
import { createProductsDetailState } from './products-detail/products-detail.reducer';
import { createPaymentState } from './payment/payment.reducer';

const appReducer = combineReducers({
  notificationState: createNotificationState.reducer,
  toastState: createSherpaToastState.reducer,
  loaderState: createLoaderState.reducer,
  createSendEmailState: createSendEmailState.reducer,
  tokenState: createTokenState.reducer,
  otpState: createOTPState.reducer,
  userSettingsState: createUserSettingsState.reducer,
  userState: createUserState.reducer,
  productsState: createProductsState.reducer,
  paymentInfoState: createPaymentInfoState.reducer,
  productsDetailState: createProductsDetailState.reducer,
  paymentState: createPaymentState.reducer
});

const rootReducer = (state, action) => {
  if (action.type === 'UNMOUNT') {
    return appReducer(undefined, action);
  }
  if (action.type === 'MOUNT') {
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.TAG !== 'PRO'
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

export type AppDispatch = typeof store.dispatch;
