import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@core/store';

export interface NotificationState {
  show: boolean;
  data: NotificationData;
}

export interface NotificationData {
  type: NotificationTypes;
  name: string;
  message: string;
  stackError?: string;
  actions?: string[];
}

export type NotificationTypes = 'success' | 'error' | 'warning' | 'information';

const initialState: NotificationState = {
  show: null,
  data: null
};

export const createNotificationState = createSlice({
  name: 'NotificationState',
  initialState,
  reducers: {
    show(_, { payload }: PayloadAction<NotificationData>) {
      return {
        show: true,
        data: { ...payload }
      };
    },
    dismiss() {
      return {
        ...initialState,
        show: false
      };
    }
  }
});

export const dismissNotification = createNotificationState.actions.dismiss;
export const showNotification = createNotificationState.actions.show;

export const notificationSelector = (state: RootState): NotificationState => state.notificationState;
