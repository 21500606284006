import { decrypt, encrypt } from '@avaldigitallabs/bbog-pb-lib-frontend-commons';
import { ActionReducerMapBuilder, AnyAction } from '@reduxjs/toolkit';

const isActionAllowedToSave = (key: string, allowedToSaveActions: string[], action: AnyAction): boolean => {
  return allowedToSaveActions?.map(saveAction => `${key}/${saveAction}`).includes(action.type);
};

const isActionInit = (action: AnyAction): boolean => action.type.includes('MOUNT');

export const sessionStorageReducer = <T>(
  builder: ActionReducerMapBuilder<T>,
  key: string,
  allowedToSaveActions: string[]
): void => {
  builder
    .addMatcher(isActionAllowedToSave.bind(null, key, allowedToSaveActions), state => {
      const sessionKey = process.env.TAG === 'LOCAL' ? key : btoa(key);
      sessionStorage.setItem(sessionKey, process.env.TAG === 'LOCAL' ? JSON.stringify(state) : encrypt(state));
    })
    .addMatcher(isActionInit, state => {
      const sessionKey = process.env.TAG === 'LOCAL' ? key : btoa(key);
      const savedSessionData = sessionStorage.getItem(sessionKey);
      const parsedSavedSessionData = () => (savedSessionData ? JSON.parse(savedSessionData) : null);
      const savedState = process.env.TAG === 'LOCAL' ? parsedSavedSessionData() : decrypt(savedSessionData);
      if (savedState) {
        return { ...state, ...savedState };
      }
    });
};
