import { SherpaToastData } from '@components/bdb-at-toast/store/toast.store';
import { NotificationTypes } from '@components/bdb-ml-modal/store/notification.store';
import { VoucherState } from '@pages/voucher/model/voucher.model';
import { NotificationData } from '@notification';

export const PREFIX_MODAL_TEALIUM_EVENT = 'modal';
export const PREFIX_TOAST_TEALIUM_EVENT = 'mensaje';
export const PREFIX_VOUCHER_TEALIUM_EVENT = 'mensaje';

const TOAST_TYPE_MAP = {
  success: 'exitoso',
  error: 'de_error',
  info: 'informativo'
};

const VOUCHER_TYPE_MAP = {
  success: 'exitoso',
  error: 'error',
  pending: 'info'
};

export const getModalTypeDesc = (type: NotificationTypes): string => {
  return `${PREFIX_MODAL_TEALIUM_EVENT}_${type === 'information' ? 'info' : type}`;
};

export const getModalDescription = (notification: NotificationData): string => {
  return [notification.name, notification.message].join(': ');
};

export const getToastTypeDesc = (type: string): string => {
  return `${PREFIX_TOAST_TEALIUM_EVENT}_${TOAST_TYPE_MAP[type.toLowerCase()]}`;
};

export const getToastDescription = (notification: SherpaToastData): string => {
  const { title, message } = notification;
  const description = [];

  /* istanbul ignore else */
  if (title) description.push(title);
  /* istanbul ignore else */
  if (message) description.push(message);

  return description.join(': ');
};

export const getVoucherTypeDesc = (type: VoucherState): string => {
  return `${PREFIX_VOUCHER_TEALIUM_EVENT}_${VOUCHER_TYPE_MAP[type]}`;
};
