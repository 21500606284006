import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, SendEmailNotificationRs } from './send-email.entity';

export const createSendEmailState = createSlice({
  name: 'CreateSendEmailState',
  initialState,
  reducers: {
    setSendEmailDataResponse(state, { payload }: PayloadAction<SendEmailNotificationRs>) {
      state.response = payload;
    },
    createSendEmailError(state, { payload }: PayloadAction<Error>) {
      state.error = payload;
    },
    resetSendEmailData(state) {
      state.response = initialState.response;
      state.error = null;
    }
  }
});

export const createSendMailActions = createSendEmailState.actions;
