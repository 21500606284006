import { RootState } from '../index';

export interface LoginRq {
  customer: {
    identificationType: string;
    identificationNumber: string;
    channel: string;
    remoteAddress: string;
  };
  password: string;
  deviceId?: string;
  numberCard?: string;
  recaptchaToken: string;
}

export interface UserState {
  accessToken: string;
  tokenBank: string;
  fullName: string;
  identificationNumber: string;
  identificationType: string;
  telephone: string;
  email: string;
  sessionId: string;
  hasActiveToken: boolean;
  tokenVersion: string;
  cdtOwner: string;
  traceability: string;
  channel: string;
}

export const initialState: UserState = {
  accessToken: null,
  tokenBank: null,
  fullName: null,
  identificationNumber: null,
  identificationType: null,
  telephone: null,
  email: null,
  sessionId: null,
  hasActiveToken: null,
  tokenVersion: null,
  cdtOwner: null,
  traceability: null,
  channel: null
};

export const isLoginSuccess = (state: RootState): boolean => (state.userState ? !!state.userState.accessToken : false);

export const userStateSelector = (state: RootState): UserState => state.userState;
