import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@core/store';
import { useSession } from '../../../../core/hooks/session.hook';
import { callUpdatePayInfoEffect } from '@pages/payment/components/payment-info/store/payment-info.effect';
import { paymentInfoSelector } from '@pages/payment/components/payment-info/store/payment-info.select';
import { useEffect, useState } from 'react';

interface IdleProps {
  isIdle: boolean;
  isTimeout: boolean;
  remaining: number;
}

export const useIdle = (): IdleProps => {
  const timeout = 120000;
  const idle = 60000;

  const dispatch: AppDispatch = useDispatch();

  const [remaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  const { transactionTraceabilityCode, transactionStatus } = useSelector(paymentInfoSelector);
  const { references } = useSelector(paymentInfoSelector);

  const { logoutAndNavigateToAvalPayCenter } = useSession();

  useEffect(() => {
    const setIntervalCb = () => {
      setRemaining(getRemainingTime());
    };
    const intervalSetted = setInterval(setIntervalCb, 1000);
    return () => {
      clearInterval(intervalSetted);
    };
  }, []);

  const checkIdle = (): void => {
    setIsIdle(remaining <= idle);
    setIsTimeout(remaining === 0);
  };

  useEffect(checkIdle, [remaining]);

  const onActiveHandler = (): void => {
    reset();
  };

  const abortTransaction = (): void => {
    if (transactionTraceabilityCode && transactionStatus === 'REGISTERED') {
      dispatch(callUpdatePayInfoEffect(transactionTraceabilityCode)).finally(() =>
        logoutAndNavigateToAvalPayCenter(references?.PortalURL)
      );
    } else {
      logoutAndNavigateToAvalPayCenter(references?.PortalURL);
    }
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout,
    onActive: onActiveHandler,
    onIdle: abortTransaction
  });

  return { isIdle, isTimeout, remaining };
};
