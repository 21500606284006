// ToDo check TransactionTypes in Aval Pay Center transactions
export enum TransactionTypes {
  TRANSFER = 'TRANSFER',
  INVESTMENT = 'INVESTMENT',
  DISINVESTMENT = 'DISINVESTMENT',
  TRANSFIYA = 'TRANSFIYA',
  DONATION = 'DONATION',
  CREDIT_CARD_ADVANCE = 'CREDIT_CARD_ADVANCE',
  LOAN_PAYMENT = 'LOAN_PAYMENT',
  BILL_PAYMENT = 'BILL_PAYMENT',
  CREDISERVICE = 'CREDISERVICE',
  PAYROLL_ADVANCE = 'PAYROLL_ADVANCE',
  RECHARGE = 'RECHARGE',
  QR_PURCHASE = 'QR_PURCHASE'
}

export interface SendEmailNotificationRq {
  transactionType: TransactionTypes;
  emailAddresses: string[];
  subject: string;
  parameter: { [key: string]: string };
}

export interface SendEmailNotificationRs {
  message: string;
}

export interface CreateSendEmailState {
  response: SendEmailNotificationRs;
  error: Error | null;
}

export const initialState: CreateSendEmailState = {
  response: null,
  error: null
};
