import store from '../store';

interface SessionI {
  logout: () => void;
  logoutAndNavigateToAvalPayCenter: (portalURL: string) => void;
  clearSession: () => void;
}

export const useSession = (): SessionI => {
  const logout = (): void => {
    clearSession();
    store.dispatch({ type: 'UNMOUNT' });
  };

  const logoutAndNavigateToAvalPayCenter = (portalURL: string): void => {
    clearSession();
    window.location.assign(portalURL || process.env.APC_HOME_URL);
  };

  const clearSession = (): void => {
    sessionStorage.clear();
    // ToDo add store reset
  };

  return { logout, logoutAndNavigateToAvalPayCenter, clearSession };
};
