export interface SimValidationRs {
  phoneNumber: string;
  provider: string;
  isSecure: boolean;
  isValid: boolean;
}

interface OTPState {
  simData: SimValidationRs;
  isOTPValid: boolean;
  hasOTPBeenSent: boolean;
  attemps: number;
}

export const initialState: OTPState = {
  simData: null,
  isOTPValid: null,
  attemps: 3,
  hasOTPBeenSent: false
};

export interface GetOtpReq {
  refType: '01';
  name: 'LOGIN'; //TODO: aplica para aval pay center?
  operator: string;
  operationType?: string; // TODO: hay que mandar esto con la nueva versión de los servicios?
}

export interface GetOtpRes {
  message: string;
}
