import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Loader from './app/components/loader/Loader';
import BdbAtToastImpl from './app/components/bdb-at-toast/BdbAtToastImpl';
import BdbMlModalImp from './app/components/bdb-ml-modal/BdbMlModalImp';
import IdleNotification from './app/components/idle/IdleNotification';
import { isLoadingSelector } from './app/components/loader/store/loader.store';
import { toastStateSelector } from './app/components/bdb-at-toast/store/toast.store';
import { notificationSelector } from './app/components/bdb-ml-modal/store/notification.store';
import PrivateRoute from './app/components/private-route/PrivateRoute';
import { tealium } from '@utils/tealium.utils';
import {
  getModalDescription,
  getModalTypeDesc,
  getToastDescription,
  getToastTypeDesc
} from '@utils/notification.utils';

const Voucher = lazy(() => import('./app/pages/voucher/Voucher'));
const Login = lazy(() => import('./app/pages/login/Login'));
const Payment = lazy(() => import('./app/pages/payment/Payment'));

export interface AppProps {
  navigate: (route: string) => void;
}

export default (): JSX.Element => {
  const isLoading = useSelector(isLoadingSelector);
  const { show: showToast, data: toastData } = useSelector(toastStateSelector);
  const { show: showModal, data: modalData } = useSelector(notificationSelector);

  useEffect(() => {
    if (modalData) {
      tealium.link({
        ga_event: getModalTypeDesc(modalData.type),
        Event_Label: getModalDescription(modalData)
      });
    }
  }, [modalData]);

  useEffect(() => {
    if (toastData) {
      tealium.link({
        ga_event: getToastTypeDesc(toastData.type),
        Event_Label: getToastDescription(toastData)
      });
    }
  }, [toastData]);

  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute component={Payment} path="/payment" />
            <PrivateRoute component={Voucher} path="/voucher" />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <IdleNotification />
      {isLoading && <Loader />}
      {showToast && <BdbAtToastImpl type={toastData.type} titleToast={toastData.title} message={toastData.message} />}
      {showModal && modalData && modalData.name && (
        <BdbMlModalImp
          icon={modalData.type}
          title={modalData.name}
          message={modalData.message}
          controls={modalData.actions ? modalData.actions.map(action => ({ text: action })) : null} // ToDo: implements dinamic call to action
        />
      )}
    </Fragment>
  );
};
