import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

export interface GetBalanceRq {
  productsInfo: Array<ProductInfo>;
}

export interface ProductInfo {
  acctId: string;
  acctType: string;
  acctSubType: string;
  officeId: string;
  refType: string;
  refId: string;
}

export interface ProductDetail {
  accountId: string;
  accountType: string;
  status?: string;
  expDate: string;
  balanceDetail: unknown;

  // fiduciary
  canRecId?: string;
  name?: string;

  // loan
  loanName?: string;
  nextPmtCurAmt?: string;
  dueDt?: string;
  openDt?: string;
  upDt?: string;
  feeDetails?: unknown;

  // demand
  overdraftDays?: string;

  // credit-card
  minPmtCurAmt?: string;
}

export interface ProductDetailState {
  productsDetail: EntityState<ProductDetail>;
}

export const productsDetailAdapter = createEntityAdapter<ProductDetail>({
  selectId: (productDetail: ProductDetail) => productDetail.accountId
});

export const initialState: ProductDetailState = {
  productsDetail: productsDetailAdapter.getInitialState({
    ids: [],
    entities: {}
  })
};
