import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { BdbAtToast } from '@sherpa-react/components';
import { dismissToast } from './store/toast.store';

export interface BdbAtToastImplProps {
  type: string;
  titleToast: string;
  message: string;
  showTime?: number;
  onDismiss?: () => void;
}

const BdbAtToastImpl = ({
  type,
  titleToast,
  message,
  showTime = 4000,
  onDismiss
}: BdbAtToastImplProps): JSX.Element => {
  const toastRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    /* istanbul ignore next */
    if (toastRef.current.show) toastRef.current.show();

    setTimeout(() => {
      /* istanbul ignore next */
      if (toastRef.current.close) toastRef.current.close();
      dispatch(dismissToast());
    }, showTime);
  }, []);

  const closeToastHandler = () => {
    onDismiss && onDismiss();
    /* istanbul ignore next */
    if (toastRef.current.close) toastRef.current.close();
    dispatch(dismissToast());
  };

  return createPortal(
    <BdbAtToast
      data-testid="app-toast-impl"
      ref={toastRef}
      type={type}
      titleToast={titleToast}
      message={message}
      onCloseToast={closeToastHandler}
    ></BdbAtToast>,
    document.getElementById('overlay-root')
  );
};

export default BdbAtToastImpl;
