import { getCurrentModule } from './url.utils';

export const CUSTOM_EVENT_COMPONENT = 'div';
export const CUSTOM_TEALIUM_EVENT = 'event';

export interface TagDataTealium {
  Event_Label: string;
  tealium_event: string;
  Event_category: string;
  event_component: string;
  ga_event: string;
}

export const CUSTOM_TEALIUM_DATA: Partial<TagDataTealium> = {
  tealium_event: CUSTOM_TEALIUM_EVENT,
  event_component: CUSTOM_EVENT_COMPONENT,
  Event_Label: ''
};

const GLOBAL_TEALIUM_OBJECT_NAME = 'utag';
const GLOBAL_TEALIUM_CONFIG_NAME = 'utag_cfg_ovrd';
const GLOBAL_TEALIUM_DATA_NAME = 'utag_data';

const tealium = () => {
  const config = () => {
    window[GLOBAL_TEALIUM_CONFIG_NAME] = { noview: true };
    window[GLOBAL_TEALIUM_DATA_NAME] = {};
  };

  const track = (tealiumEvent: string, data?: Partial<TagDataTealium>) => {
    if (window[GLOBAL_TEALIUM_OBJECT_NAME] === undefined) return;

    CUSTOM_TEALIUM_DATA.Event_category = getCurrentModule();

    window[GLOBAL_TEALIUM_OBJECT_NAME].track(tealiumEvent, { ...CUSTOM_TEALIUM_DATA, ...data });
  };

  const view = (data: Partial<TagDataTealium>) => {
    track('view', data);
  };

  const link = (data: Partial<TagDataTealium>) => {
    track('link', data);
  };

  return { config, view, link };
};

const tealiumInstace = tealium();

export { tealiumInstace as tealium };
