import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, PaymentsGenericResponse } from './payment.entity';
import { sessionStorageReducer } from '../session-storage.reducer';
import { Product } from '../products/products.entity';

export const createPaymentState = createSlice({
  name: 'PaymentState',
  initialState,
  reducers: {
    setPaymentSuccess(state, { payload }: PayloadAction<PaymentsGenericResponse>) {
      state.paymentResponse = payload;
      state.error = null;
    },
    setPaymentError(state, { payload }: PayloadAction<Error>) {
      state.error = payload;
      state.paymentResponse = null;
    },
    setSelectedProduct(state, { payload }: PayloadAction<Product>) {
      state.productSelected = payload;
    },
    resetState(state) {
      state.paymentResponse = initialState.paymentResponse;
      state.productSelected = initialState.productSelected;
      state.error = initialState.error;
    }
  },
  extraReducers: builder => {
    sessionStorageReducer(builder, 'PaymentState', ['setPaymentSuccess', 'setPaymentError', 'setSelectedProduct']);
  }
});

export const paymentActions = createPaymentState.actions;
