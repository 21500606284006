import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { BdbMlModal } from '@sherpa-react/components';
import { dismissNotification } from './store/notification.store';

export interface BdbMlModalImpProps {
  icon: IconTypes;
  title?: string;
  message?: string;
  controls: BdbMlModalControls[];
}

export interface BdbMlModalControls {
  text: string;
  action?: () => void;
}

type IconTypes = 'success' | 'error' | 'warning' | 'information';

const BdbMlModalImp = ({ title, message = '', icon = 'success', controls }: BdbMlModalImpProps): JSX.Element => {
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  useEffect(() => {
    if (controls && controls.length > 0) {
      const optionButtons: { id: string; value: string }[] = [];
      controls.forEach(control => {
        optionButtons.push({ id: `modal-control-${optionButtons.length}`, value: control.text });
      });
      modalRef.current.optionsButtons = JSON.stringify(optionButtons);
      modalRef.current.isClose = false;
    } else {
      modalRef.current.hiddenButtons = true;
      modalRef.current.isClose = true;
    }
    /* istanbul ignore next */
    if (modalRef.current.openAlert) modalRef.current.openAlert();
  }, []);

  const handleButtonClicked = (event: CustomEvent) => {
    const actionControl = controls.find(control => control.text === event.detail.value);
    /* istanbul ignore else */
    if (!!actionControl && !!actionControl.action) {
      actionControl.action();
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(dismissNotification());
  };

  return (
    <BdbMlModal
      idEl="bdb-modal-info"
      data-testid="bdb-modal-info"
      ref={modalRef}
      titleModal={title}
      subTitle={message}
      icon={icon}
      onButtonAlertClicked={handleButtonClicked}
      onCloseAlert={handleCloseModal}
    />
  );
};

export default BdbMlModalImp;
