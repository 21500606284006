import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@core/store';

interface SherpaToastState {
  show: boolean;
  data: SherpaToastData;
}

export interface SherpaToastData {
  type: string;
  title: string;
  message: string;
}

const initialState: SherpaToastState = {
  show: null,
  data: null
};

export const createSherpaToastState = createSlice({
  name: 'SherpaToastState',
  initialState,
  reducers: {
    show(state, { payload }: PayloadAction<SherpaToastData>) {
      state.data = { ...payload };
      state.show = true;
    },
    dismiss(state) {
      state.data = null;
      state.show = false;
    }
  }
});

export const dismissToast = createSherpaToastState.actions.dismiss;
export const showToast = createSherpaToastState.actions.show;

export const toastStateSelector = (state: RootState): SherpaToastState => state.toastState;
