export const dayOfTheWeek = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
export const monthOfYear = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre'
];

export const getPlainDateStr = (date?: Date): string => {
  const newDate = date ? new Date(date.getTime()) : new Date();
  let dayStr = dayOfTheWeek[newDate.getDay()];
  dayStr = dayStr.charAt(0).toUpperCase() + dayStr.slice(1);
  const monthStr = monthOfYear[newDate.getMonth()];
  const getTime = getFormatAmPmTime(newDate);
  return `${dayStr}, ${monthStr} ${newDate.getDate()}, ${newDate.getFullYear()}, ${getTime}`;
};

export const getFormatAmPmTime = (date: Date): string => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? 'p.m' : 'a.m';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds} ${ampm}`;
  return strTime;
};

export const getMmSsTime = (milis: number): string => {
  let seconds = milis / 1000;
  seconds = seconds % 3600;
  const minutes = Math.trunc(seconds / 60);
  seconds = Math.trunc(seconds % 60);
  return `${minutes <= 9 ? '0' + minutes : minutes}:${seconds <= 9 ? '0' + seconds : seconds}`;
};
