import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { defineCustomElements } from '@npm-bbta/bbog-dig-dt-webcomponents-lib/loader';

import './styles.scss';
import App from './App';
import store from '@core/store';
import { createInterceptors } from '@avaldigitallabs/bbog-pb-lib-frontend-commons';
import { axiosADLInstance } from '@core/constants/constants';
import { tealium } from '@utils/tealium.utils';
interface Mount {
  unmount: () => void;
}

const isEncrypted = !['DEV', 'LOCAL'].includes(process.env.TAG);

tealium.config();

const mount = async (el: Element): Promise<Mount> => {
  defineCustomElements(window);
  const interceptors = await createInterceptors(isEncrypted, axiosADLInstance);
  store.dispatch({ type: 'MOUNT' });
  ReactDom.render(
    <Provider store={store}>
      <App />
    </Provider>,
    el
  );

  const unmount = () => {
    store.dispatch({ type: 'UNMOUNT' });
    ReactDom.unmountComponentAtNode(el);
    interceptors.destroy();
  };

  return { unmount };
};

mount(document.getElementById('standalone-root'));

export { mount };
