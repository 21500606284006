import { NotificationData } from '@notification';
import { SherpaToastData } from '@components/bdb-at-toast/store/toast.store';

export const EXIT_BUTTON_TEXT = 'Regresar al Comercio';

export const ERROR_MESSAGE: NotificationData = {
  type: 'error',
  name: 'Algo ha fallado',
  message: 'En estos momentos no pudimos realizar la transacción. Serás devuelto al Comercio para volver a intentarlo.'
};

export const TIMEOUT_MESSAGE: NotificationData = {
  type: 'error',
  name: 'El tiempo ha caducado',
  message:
    'El tiempo para realizar la transacción ha expirado. Por seguridad serás devuelto al Comercio para volver a intentarlo.'
};

export const COMPLETED_TRANSACTION: NotificationData = {
  type: 'information',
  name: 'Transacción finalizada',
  message: 'Serás devuelto al Comercio para conocer el resultado de la transacción.'
};

export const TECHNICAL_ERROR: SherpaToastData = {
  type: 'error',
  message: 'En estos momentos no podemos realizar esta operación',
  title: 'Algo ha fallado'
};

export const SUCCESS_OTP_MSG = 'Validate OTP Operation Success';
